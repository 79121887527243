<template>
  <b-sidebar
    id="add-new-live-sidebar"
    :visible="isAddNewLiveSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm(); $refs.refFormObserver.reset()"
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Adicionar live
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="submit()"
          @reset.prevent="resetForm"
          enctype="multipart/form-data"
        >
          <!-- Título -->
          <validation-provider
            v-slot="{ errors }"
            name="title"
            rules="required"
          >
            <b-form-group
              label="Título"
              label-for="title"
            >
              <b-form-input
                id="title"
                v-model="form.title"
                placeholder="Digite um título"
                drop-placeholder="Arraste seu arquivo para aqui..."
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Streaming -->
          <validation-provider
            v-slot="{ errors }"
            name="streaming"
            rules="required"
          >
            <b-form-group
              label="Streaming"
              label-for="streaming"
            >
              <v-select
                id="streaming"
                v-model="form.streaming"
                :options="streamingOptions"
                placeholder="Escolha o streaming"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Link -->
          <validation-provider
            v-slot="{ errors }"
            name="link"
            rules="required"
          >
            <b-form-group
              label="Link"
              label-for="link"
            >
              <b-form-input
                id="link"
                v-model="form.link"
                placeholder="Insira o link do streaming"
                drop-placeholder="Arraste seu arquivo para aqui..."
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Banner -->
          <validation-provider
            v-slot="{ errors }"
            name="banner"
            rules="required"
          >
            <b-form-group
              label="Banner"
              label-for="banner"
            >
              <b-form-file
                id="banner"
                v-model="form.banner"
                placeholder="Escolha um banner"
                drop-placeholder="Arraste seu arquivo para aqui..."
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Description -->
          <validation-provider
            v-slot="{ errors }"
            name="description"
            rules="required"
          >
            <b-form-group
              label="Descrição"
              label-for="description"
              :state="errors.length > 0 ? false : null"
            >
              <b-form-textarea
                id="description"
                v-model="form.description"
                placeholder="Digite a descrição da live"
                rows="3"
                max-rows="6"
                :state="errors.length > 0 ? false : null"
                style="height: 150px;"
              />

              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
          
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              :disabled="isBusy"
            >
              <b-spinner v-if="isBusy" />
              <template v-else>
                Adicionar
              </template>
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancelar
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import axios from "axios";
import {
  BSidebar, BForm, BFormGroup, BFormInput, BButton, VBTooltip
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { required, min_value as minValue } from 'vee-validate/dist/rules'
import Ripple from 'vue-ripple-directive'
import countries from '@/@fake-db/data/other/countries'

import { Money } from 'v-money'
import vSelect from 'vue-select'

extend('required', {
  ...required,
  message: 'Esse campo é obrigatório.',
})

extend('min_length_deposit', {
  ...minValue,
  message: 'O valor minímo para depositar é R$ 1,00.',
})

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    Money,
    vSelect,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  props: {
    isAddNewLiveSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isBusy: false,
      form: {
        title: null,
        link: null,
        streaming: null,
        banner: null,
        description: null,
      },
      streamingOptions: ['cloudflare', 'youtube', 'vimeo'],
    }
  },
  computed: {
  },
  watch: {
  },
  created() {
  },
  methods: {
    resetForm() {
      this.form = {
        title: null,
        description: null,
        banner: null,
        logo: null,
      }
    },
    async submit() {
      try {
        const isValid = await this.$refs.refFormObserver.validate();
        if (!isValid) {
          return;
        }

        const formData = new FormData();
        formData.append("title", this.form.title);
        formData.append("link", this.form.link);
        formData.append("streaming", this.form.streaming);
        formData.append("banner", this.form.banner);
        formData.append("description", this.form.description);
        formData.append("event_id", this.$route.params.event_id);

        this.isBusy = true;
        const response = await axios.post(process.env.VUE_APP_API + "/events/lives/store-live", formData);
        this.isBusy = false;

        console.log(response);

        if (response.status === 200) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Operação efetuada com sucesso.",
              icon: "InfoIcon",
              variant: "success",
            },
          });
          this.$emit("close");
          this.$emit("onSaved", true);
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              icon: "InfoIcon",
              title: "Erro",
              text: "ERROR: erro ao tentar gravar as informações",
              variant: "danger",
            },
          });
        }
      } catch (err) {
        this.isBusy = false;
        this.$toast({
          component: ToastificationContent,
          props: {
            icon: "InfoIcon",
            title: "Erro ao tentar gravar as informações",
            variant: "danger",
          },
        });
        console.error(err);
      }
    },

    sortByName(a, b) {
      if (a.text < b.text) {
        return -1
      }
      if (a.text > b.text) {
        return 1
      }
      return 0
    },
    sortByDate(a, b) {
      if (a.date < b.date) {
        return -1
      }
      if (a.date > b.date) {
        return 1
      }
      return 0
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-live-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
